import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { LinkedIn } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Remark } from 'react-remark';
import { Route, HashRouter as Router, Switch, useRouteMatch } from 'react-router-dom';

import { useAPI } from '../userContext';
import { SalesforceIcon } from '../widgets';

const DebugText = ({ title, children }) => {
  const [open, set_open] = useState(false);
  return (
    <div>
      <div style={{ fontSize: '0.5rem', color: 'rgba(0, 0, 0, 0.3)' }} onClick={() => set_open(!open)}>
        {title}
      </div>
      {open && <pre>{children}</pre>}
    </div>
  );
};

const SalesforceContactLink = ({ id }) => (
  <a
    href={`https://aleph.lightning.force.com/lightning/r/Contact/${id}/view`}
    target="_blank"
    rel="noopener noreferrer"
    style={{ marginLeft: '0.5rem' }}
  >
    <SalesforceIcon fontSize="small" />
  </a>
);

const LinkedInContactLink = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '0.5rem' }}>
    <LinkedIn color="primary" fontSize="inherit" />
  </a>
);

const OneHopPath = ({ path }) => {
  const { Name, text_list, analysis_list, rank } = path;
  const text = Array.from(new Set(text_list)).join(' | ');
  const analysis = Array.from(new Set(analysis_list)).join(' | ');
  return (
    <div>
      <h4 style={{ marginTop: '0em', marginBottom: '0em' }}>
        ↳ <RelationshipWithHoverText rank={rank} text={text} analysis={analysis} /> {Name}
      </h4>
      <DebugText title="[see full path info]">{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

const RelationshipWithHoverText = ({ rank, text, analysis }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={handleClick}>[relationship={rank}]</a>
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{
          maxWidth: '70vw',
          maxHeight: '70vh',
          overflow: 'scroll',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '0.5rem',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper style={{ padding: '0.4rem' }}>
            <pre>{text}</pre>
            {analysis && <Remark>{analysis}</Remark>}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const TwoHopPath = ({ path }) => {
  const { Name, rank, text_list } = path;
  const text = Array.from(new Set(text_list)).join(' | ');
  return (
    <div>
      <h3 style={{ marginTop: '0em', marginBottom: '0em' }}>
        ↳ <RelationshipWithHoverText rank={rank} text={text} /> {Name} <SalesforceContactLink id={path.contact_id} />{' '}
        <LinkedInContactLink url={path.linkedin_profile} />
      </h3>
      <div style={{ padding: '0em 1em 0em 5em', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
        {path.path.length ? path.path.map((path) => <OneHopPath key={path.contact_id} path={path} />) : undefined}
      </div>
      <DebugText title="[see full path info]">{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

export const PathToContact = ({ contact_id, contact_name }) => {
  const api = useAPI();
  const [path, set_path] = useState({});
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState('');
  useEffect(() => {
    const fetch_path = async () => {
      set_loading(true);
      try {
        const res = await api(`pathfinder/contact/${contact_id}`);
        set_path(res);
      } catch (e) {
        set_error(e.message);
      } finally {
        set_loading(false);
      }
    };
    fetch_path();
  }, [api, contact_id]);
  const contact_label = contact_name || contact_id;
  return loading ? (
    <div style={{ padding: '1rem' }}>Finding path to {contact_label}...</div>
  ) : error ? (
    <div style={{ color: 'red' }}>Error: {error}</div>
  ) : (
    <div>
      {path.one_hop_paths.length > 0
        ? path.one_hop_paths.map((path) => <OneHopPath key={path.id} path={path} />)
        : undefined}
      {path.two_hop_paths.length
        ? path.two_hop_paths.map((path) => <TwoHopPath key={path.id} path={path} />)
        : undefined}
      <DebugText title={'See full path info (debug)'}>{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

export const ContactPathFinder = () => {
  const { params } = useRouteMatch();

  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1 style={{ marginTop: '1em' }}>Contact Path Finder</h1>
        <h2>contact id: {params.id}</h2>
        <PathToContact contact_id={params.id} />
      </div>
    </>
  );
};

export const AccountPathFinder = () => {
  const { params } = useRouteMatch();
  return (
    <div>
      <h1>Account Path Finder</h1>
      <h2>account id: {params.id}</h2>
    </div>
  );
};

const prioritize_contact = ({ contact_kind }) => {
  if (contact_kind === 'exact_title_match') return 0;
  if (contact_kind === 'executives') return 1;
  if (contact_kind === 'board_members') return 2;
  if (contact_kind === 'managers') return 3;
  if (contact_kind === 'vc_partners') return 4;
  if (contact_kind === 'employees') return 5;
};

const sort_paths = (paths) => _.sortBy(paths, [prioritize_contact, (x) => -x.score]);

const TargetContactWithPath = ({ id, name, title, linkedin_profile, score_details }) => (
  <>
    <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>
      {name} - {title}
      <SalesforceContactLink id={id} />
      <LinkedInContactLink url={linkedin_profile} />
      <span style={{ fontSize: '12px', fontWeight: 100, fontStyle: 'normal', color: 'rgba(0, 0, 0, 0.3)' }}>
        {' '}
        ( {score_details} )
      </span>
    </h3>
    <div style={{ padding: '0em 1em 0em 5em', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
      <PathToContact contact_id={id} contact_name={name} />
    </div>
  </>
);

export const IntroPathFinder = () => {
  const { params } = useRouteMatch();
  const api = useAPI();
  const [path, set_path] = useState({});
  const [path_groups, set_path_groups] = useState({});
  const [intro, set_intro] = useState({});
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState('');
  useEffect(() => {
    const fetch_path = async () => {
      set_loading(true);
      try {
        const { paths, intro } = await api(`pathfinder/intro/${params.id}`);
        set_path(sort_paths(paths));
        set_path_groups(_.groupBy(paths, 'contact_kind'));
        set_intro(intro);
      } catch (e) {
        set_error(e.message);
      } finally {
        set_loading(false);
      }
    };
    fetch_path();
  }, [api, params.id]);
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1 style={{ marginTop: '1em' }}>Intro Path Finder</h1>
        <h2>
          <b>Intro:</b> {intro.Name || params.id}
        </h2>
        {loading ? (
          <div style={{ textAlign: 'center' }}>Loading...</div>
        ) : error ? (
          <div style={{ textAlign: 'center' }}>Error: {error}</div>
        ) : (
          <>
            <div>
              <DebugText title="[see raw intro data (debug mode)]">{JSON.stringify(intro, null, 2)}</DebugText>
              <DebugText title="[see raw path (debug mode)]">{JSON.stringify(path, null, 2)}</DebugText>
            </div>
            <div>
              {path_groups.exact_title_match && (
                <>
                  <h2>Target Persona</h2>
                  {path_groups.exact_title_match.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
              {path_groups.executives && (
                <>
                  <h2>Executives at {intro.Target_Account__r.Name}</h2>
                  {path_groups.executives.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
              {path_groups.board_members && (
                <>
                  <h2>Board Members / Investors / Advisory</h2>
                  {path_groups.board_members.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
              {path_groups.managers && (
                <>
                  <h2>Managers at {intro.Target_Account__r.Name}</h2>
                  {path_groups.managers.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
              {path_groups.vc_partners && (
                <>
                  <h2>Partner at VCs that invested in {intro.Target_Account__r.Name}</h2>
                  {path_groups.vc_partners.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
              {path_groups.employees && (
                <>
                  <h2>ICs at {intro.Target_Account__r.Name}</h2>
                  {path_groups.employees.map(({ id, name, linkedin_profile, title, score_details }) => (
                    <div key={id}>
                      <TargetContactWithPath
                        id={id}
                        name={name}
                        title={title}
                        linkedin_profile={linkedin_profile}
                        score_details={score_details}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const PathFinderMain = () => {
  const { path } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route path={`${path}/contact/:id`}>
          <ContactPathFinder />
        </Route>
        <Route path={`${path}/account/:id`}>
          <AccountPathFinder />
        </Route>
        <Route path={`${path}/intro/:id`}>
          <IntroPathFinder />
        </Route>
      </Switch>
    </Router>
  );
};
